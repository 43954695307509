import React from 'react'

import { graphql } from 'gatsby'
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import Layout from '../components/layout'
import SEO from "../components/seo"
import Allergen from '../models/Allergen'

const PageAllergeni = ({ pageContext, data: { allergens, icon } }) =>  {
  const { formatMessage } = useIntl()

  return (
    <Layout icon={icon}>
      <SEO title={ formatMessage({ id: 'scheda_allergeni' }) } />
      <div className="page-allergeni px-6">
        <h1 className="page-allergeni__title text-4xl font-dancing text-center">
          <FormattedMessage id="scheda_allergeni" />
        </h1>
        { allergens.nodes.map((allergene, index) => {
          const allergen = new Allergen(allergene, pageContext.language)
          return (
            <div key={index} className="allergen flex items-center px-6 my-8">
              <div className="allergen__icon mr-8 w-10 h-10">
                <img
                  src={ allergen.icon() }
                  alt={ allergen.name() }
                  title={ allergen.name() }
                  width="42"
                  height="42"
                  className="w-10 h-10"
                />
              </div>
              <div className="allergen__name text-xl uppercase">
                { allergen.name() }
              </div>
            </div>
          )
        }) }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageAllergeniQuery {
    icon: file(relativePath: { eq: "casette.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allergens: allAirtable(filter: {table: {eq: "allergeni"}}, sort: {fields: data___id }) {
      nodes {
        data {
          traduzione {
            data {
              it
              de
              en
            }
          }
          icona {
            thumbnails {
              full {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default PageAllergeni
